import { all, takeEvery, put, call } from 'redux-saga/effects'
import { create , Edit } from 'services/trackdisapprove'
import actions from './actions'

export function* CREATE({ payload}) {
   const { 
    trackdisablecontent ,
    trackdisabletitle,
    trackdisapprovaltype,
   } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success=yield call(create,trackdisablecontent,trackdisabletitle,trackdisapprovaltype)
  if(success){
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    })
   return success
    //  return yield put(push(`/labels/labelinformation/${id}`))
  }
  
}

export function* EDIT({ payload,id }) {
  const {
    editcontent,
    edittitle,
    editdisapprovaltype
     }=payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success=yield call(Edit,editcontent,edittitle,editdisapprovaltype,id)
  if(success){
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    })
   return success
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.CREATE, CREATE),
     takeEvery(actions.EDIT, EDIT),
    // takeEvery(actions.LOAD_CURRENT_ACCOUNT, LOAD_CURRENT_ACCOUNT),
    // takeEvery(actions.LOGOUT, LOGOUT),
    // takeEvery(actions.FORGOT, FORGOT),
    // takeEvery(actions.UPDATEPASS, UPDATEPASS),
    // LOAD_CURRENT_ACCOUNT(), // run once on app load to check user auth
  ])
}
