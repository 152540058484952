import { notification } from 'antd'
import CommonApi from './Apiservices/CommonAPI'
/*eslint-disable*/


export default create

export function create(payload) {
    const state={
      res:false
    }
  CommonApi.SaveData('/api/v1/admin/price/level/create', payload)
   .then((response)=>{
    notification.success({
      message:response.data.message,
    });
  state.res=true
  return state

   }).catch(error=>{
      notification.warning({
          message:error.message,
        });
   })
   return state
  
 }

      export async function Edit(payload,id) {
        const state={
          res:false
        }
      await CommonApi.SaveData(`/api/v1/admin/price/level/update/${id}`, payload)
          .then((response)=>{
            notification.success({
              message:response.data.message,
            });
            state.res=true
            return state.res
    
             }).catch(error=>{
                notification.warning({
                    message:error.message,
                  });
             })
             return state.res
        }