import { all, takeEvery, put, call } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import { create , Edit } from 'services/tracksdetail'
import actions from './actions'

export function* CREATE({ payload, id }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success=yield call(create,payload , id)
  if(success){
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    })
   return success
  }

}

export function* EDIT({ payload,id ,Checkbox  }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
 

  const success=yield call(Edit, payload ,id , Checkbox )
  if(success){
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    })
    return yield put(push(`/tracks/tracks-detail/${id}`))
  }

}
 
export default function* rootSaga() {
  yield all([
    takeEvery(actions.CREATE, CREATE),
    takeEvery(actions.EDIT, EDIT),
    // takeEvery(actions.LOAD_CURRENT_ACCOUNT, LOAD_CURRENT_ACCOUNT),
    // takeEvery(actions.LOGOUT, LOGOUT),
    // takeEvery(actions.FORGOT, FORGOT),
    // takeEvery(actions.UPDATEPASS, UPDATEPASS),
    // LOAD_CURRENT_ACCOUNT(), // run once on app load to check user auth
  ])
}
