import { notification } from 'antd'
import CommonApi from './Apiservices/CommonAPI'

/*eslint-disable*/
export default create

export async function create(payload) {
  const state = {
    res: false
  }
  await CommonApi.SaveData('/api/v1/admin/knowledgebase/category/create', payload)
    .then((response) => {
      notification.success({
        message: response.data.message,
      });
      state.res = true
      return state

    }).catch(error => {
      notification.warning({
        message: error.message,
      });
    })
  return state
}

export function Edit(knowledgebasecategoryname, id) {
  const state = {
    res: false
  }
  const body =
  {
    knowledge_base_category_name: knowledgebasecategoryname,
  }
  CommonApi.SaveData(`/api/v1/admin/knowledgebase/category/update/${id}`, body)
    .then((response) => {
      notification.success({
        message: response.data.message,
      });
      state.res = true
      return state

    }).catch(error => {
      notification.warning({
        message: error.message,
      });
    })
  return state

}
