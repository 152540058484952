import { notification } from 'antd'
import CommonApi from './Apiservices/CommonAPI'


export default create

export async function create(disablecontent, disabletitle) {
  const state = {
    res: false
  }
  const body = {
    content: disablecontent,
    title: disabletitle
  }


  await CommonApi.SaveData('/api/v1/admin/label/disable/reason/create', body)
    .then((response) => {
      notification.success({
        message: response.data.message,
      });
      state.res = true
      return state.res

    }).catch(error => {
      notification.warning({
        message: error.message,
      });
    })
  return state.res
}
export async function Edit(payload, id) {
  const state = {
    res: false
  }
  await CommonApi.SaveData(`/api/v1/admin/label/disable/reason/update/${id}`, payload)
    .then((response) => {
      notification.success({
        message: response.data.message,
      });
      state.res = true
      return state.res

    }).catch(error => {
      notification.warning({
        message: error.message,
      });
    })
  return state.res
}