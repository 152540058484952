

export async function getLeftMenuData() {
  return [
    {
      title: 'Dashboard',
      key: 'dashboardAlpha',
      url: '/dashboard',
      icon: 'icmn icmn-home',
    },
    {
      divider: true,
    },
    {
      title: 'Auditions',
      key: 'antComponents',
      url: '/audition',
      icon: 'icmn icmn-mic',
    },
    {
      divider: true,
    },
    {
      title: 'Labels',
      key: 'dashboardDocs',
      url: '/labels',
      icon: 'fa fa-street-view',
      className: 'drawer-handle'
    },
    {
      divider: true,
    },
    {
      title: 'Tracks',
      key: 'dashboardBeta',
      url: '/tracks',
      icon: 'icmn icmn-music',
    },
    {
      divider: true,
    },
    {
      title: 'Label Variables',
      key: 'labelvariabel',
      icon: 'icmn icmn-stats-bars',
      children: [
        {
          divider: true,
        },
        {
          title: 'Label Medals',
          key: 'labelmedals',
          url: '/label-variable/labelmedals',
          icon: 'icmn icmn-trophy',
        },
        {
          divider: true,
        },
        {
          key: 'Label Percentages',
          title: 'Label Percentages',
          url: '/label-variable/label-percentages',
          icon: 'fa fa-percent'
        },
        {
          divider: true,
        },
        {
          key: 'Price Levels',
          title: 'Price Levels',
          url: '/label-variable/price-labels',
          icon: 'icmn icmn-clipboard'
        },
      ],
    },
    {
      divider: true,
    },
    {
      title: 'Genres',
      key: 'all_genres',
      url: '/genres',
      icon: 'icmn icmn-google3',
    },
    {
      divider: true,
    },
    {
      title: 'Instruments',
      key: 'instrument',
      url: '/instrument',
      // icon: 'icmn icmn-file-text',
      icon: 'fa fa-wrench',
    },
    {
      divider: true,
    },
    {
      title: 'Orders',
      key: 'order_management',
      icon: 'icmn icmn-cart',
      url: '/orders/order',
    },

    {
      divider: true,
    },

    {
      title: 'Pages',
      key: 'dashboardCrypto',
      url: '/pages',
      icon: 'icmn icmn-file-text',
    },
    {
      divider: true,
    },
    {
      title: 'Users',
      key: 'dashboardGamma',
      url: '/users',
      icon: 'icmn icmn-user',
    },
    {
      divider: true,
    },


    // {
    //   title: 'Label Medals',
    //   key: 'labelmedals',
    //   url: '/labelmedals',
    //   icon: 'icmn icmn-trophy',
    // },
    // {
    //   divider: true,
    // },


    // {
    //   title: 'Category',
    //   key: 'category',
    //   url: '/categorymanagement',
    //   icon: 'icmn icmn-file-text',
    // },
    // {
    //   divider: true,
    // },

    // {
    //   title: 'Mail Templates',
    //   key: 'Mail',
    //   url: '/mail',
    //   icon: 'fa fa-envelope-open',
    // },
    {
      divider: true,
    },
    {
      title: 'FAQ',
      key: 'faq',
      icon: 'fa fa-question',
      children: [
        {
          divider: true,
        },
        {
          key: 'Faq',
          title: 'FAQs',
          url: '/faq/faqs',
          icon: 'fa fa-question',
        },
        {
          divider: true,
        },
        {
          key: 'FaqCategory',
          title: 'FAQ Category',
          url: '/faq/faq-category',
          icon: 'fa fa-question',
        },
        {
          divider: true,
        },
        {
          key: 'FaqTags',
          title: 'FAQ Tags',
          url: '/faq/faq-tags',
          icon: 'fa fa-tag',
        },

      ],
    },
    {
      divider: true,
    },
    {
      title: 'Coupons',
      key: 'coupons',
      url: '/coupons',
      icon: 'fa fa-percent',
    },
    {
      divider: true,
    },
    {
      title: 'Banners',
      key: 'banners',
      url: '/banners',
      icon: 'fa fa-bookmark',
    },
    {
      title: 'Hotcoin',
      key: 'hotcoin',
      icon: 'fa fa-bitcoin',
      children: [
        {
          title: 'Products',
          key: 'hotcoin-products',
          url: '/hotcoin-products',
          icon: 'fa fa-shopping-bag',
        },
        {
          title: 'Rewards',
          key: 'hotcoin-rewards',
          url: '/hotcoin-rewards',
          icon: 'fa fa-certificate',
        },        
      ]
    },
    {
      divider: true,
    },
    {
      title: 'Mailing List Types',
      key: 'Mailing List Types',
      url: '/mailingListTypes',
      icon: 'fa fa-envelope',
    },
    {
      divider: true,
    },
    {
      title: 'Payment Methods',
      key: 'payment',
      icon: 'fa fa-money',
      url: '/payment'
    },
    {
      divider: true,
    },
    {
      title: 'Currency',
      key: 'currency',
      icon: 'fa fa-money',
      url: '/currency'
    },
    {
      divider: true,
    },
    {
      title: 'Support',
      key: 'support',
      icon: '	fa fa-support',
    },
    {
      divider: true,
    },
    {
      title: 'Track Reports',
      key: 'trro',
      icon: 'fa fa-ban',
      children: [
        {
          divider: true,
        },
        {
          key: 'trr',
          title: 'Track Report Reason',
          url: '/all-track-reports',
          icon: 'fa fa-ban',
        },
        {
          divider: true,
        },
        {
          key: 'trc',
          title: 'Track Reported by Customer',
          url: '/trackreported-bycustomer',
          icon: 'fa fa-ban',
        },

      ],
    },
    {
      divider: true,
    },
    {
      title: 'Settings',
      key: 'setting',
      icon: 'icmn icmn-cog utils__spin-delayed--pseudo-selector',
      url: '/setting'
    },
    {
      divider: true,
    },

  ]
}
export async function getTopMenuData() {
  return [
    {
      title: 'Settings',
      key: 'settings',
      icon: 'icmn icmn-cog utils__spin-delayed--pseudo-selector',
    }
  ]
}
