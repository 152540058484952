import { all, takeEvery, put, call } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import { create , Edit } from 'services/mailinglisttype'
import actions from './actions'

/*eslint-disable*/
export function* CREATE({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success=yield call(create,payload)
  if(success){
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    })
    return yield put(push('/mailingListTypes'))

  }
  
}
export function* EDIT({ payload,maillistid }) {
  const { 
    name,
    description,
   } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  
  const success=yield call(Edit , name ,description,maillistid)
  if(success){
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    })
    return yield put(push('/mailingListTypes'))
  }
  
}


export default function* rootSaga() {
  yield all([
    takeEvery(actions.CREATE, CREATE),
    takeEvery(actions.EDIT, EDIT),
  ])
}