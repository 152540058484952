import { all, takeEvery, put, call } from 'redux-saga/effects'
import {  Edit } from 'services/contract'
import actions from './actions'

/*eslint-disable*/

export function* EDIT({ payload , id}) {
  const { 
    sell_copyright:sellcopyright ,
    pro:contractpro,
    pro_ipi_cae:contractproipicae,
    publisher:contractpublisher,
    publisher_ipi_cae:contractpublisheripicae
  } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  
  const success=yield call(Edit , sellcopyright , contractpro , contractproipicae , contractpublisher , contractpublisheripicae ,  id)
  if(success){
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    })
 return yield put(push(`/labels/labelinformation/${id}`))
  }
  
}


export default function* rootSaga() {
  yield all([
    takeEvery(actions.EDIT, EDIT),
  ])
}
