import { all, takeEvery, put, call } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import { create , Edit} from 'services/category'
import actions from './actions'

export function* CREATE({ payload , file}) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success=yield call(create , payload , file)
  if(success){
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    })
    return yield put(push('/categorymanagement'))
  }
}
export function* EDIT({ payload,categoryid , file }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success=yield call(Edit,payload,categoryid , file)
  if(success){
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    })
    return yield put(push('/categorymanagement'))
  }
  
}


export default function* rootSaga() {
  yield all([
    takeEvery(actions.CREATE, CREATE),
     takeEvery(actions.EDIT, EDIT),
  ])
}
