import { all, takeEvery, put, call } from 'redux-saga/effects'
import { create ,Edit } from 'services/medalassign'
import actions from './actions'


export function* CREATE({ payload,labelid,medalid,id }) {
  
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success=yield call(create,payload,labelid,medalid,id)
  if(success){
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    })
   return success
  }
  
}
export function* EDIT({ payload , id}) {
    const { labelname , paypalaccount , facebookinvited ,bioapproved ,autoapprovetracks ,labeldescription ,labelbiography ,facebookurl,
      adminremarks ,facebookinvitedat , labelpublisher , publisheripicae ,proipicae ,labelpro ,agreementstiplykey,labelagreementdocument
    } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success=yield call(Edit,labelname,paypalaccount,facebookinvited,bioapproved,autoapprovetracks,labeldescription,labelbiography,facebookurl,
  adminremarks,facebookinvitedat,labelpublisher,publisheripicae,proipicae,labelpro,agreementstiplykey,labelagreementdocument,id)
  if(success){
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    })
    return success

  }
  
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.CREATE, CREATE),
     takeEvery(actions.EDIT, EDIT),
  ])
}
