import { all, takeEvery, put, call } from 'redux-saga/effects'
import { notification } from 'antd'
import { login, currentAccount, logout, Forgot, Updatepass } from 'services/user'
import actions from './actions'

export function* LOGIN({ payload }) {
  const { email, password, code } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(login, email, password, code)
  if (success[0].data) {
    const { data } = success[0].response.data
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
        name: data.name,
        email : data.email,
        role: data.type === 'Admin' ? 'admin' : '',
        permissions: data.permissions,
        authorized: true,
        has_2fa: data.has_2fa
      },
    })
    notification.success({
      message: 'Logged In',
      description: success[0].response.data.message,
    })
  }
}

export function* LOAD_CURRENT_ACCOUNT() {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(currentAccount)
  if (response) {
    const { data } = response[0].response.data
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
        name: data.name,
        email : data.email,
        role: data.type === 'Admin' ? 'admin' : '',
        permissions: data.permissions,
        authorized: true,
        has_2fa: data.has_2fa
      },
    })
  }
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* LOGOUT() {
  yield call(logout)
  yield put({
    type: 'user/SET_STATE',
    payload: {
      id: '',
      name: '',
      role: '',
      email: '',
      avatar: '',
      authorized: false,
      loading: false,
    },
  })
}
export function* FORGOT({payload}){
  const {email}=payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success=yield call(Forgot,email);
  if(success){
    notification.success({
      message: 'Email Sent',
      description: 'We have successfully Sent Email',
    })

  }
}
export function* UPDATEPASS({payload,token}){
  const {email,password,cpassword}=payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success=yield call(Updatepass,email,password,cpassword,token);
  if(success){
    notification.success({
      message: 'Password Changed',
      description: 'Password Changed',
    })

}
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOGIN, LOGIN),
    takeEvery(actions.LOAD_CURRENT_ACCOUNT, LOAD_CURRENT_ACCOUNT),
    takeEvery(actions.LOGOUT, LOGOUT),
    takeEvery(actions.FORGOT, FORGOT),
    takeEvery(actions.UPDATEPASS, UPDATEPASS),
    LOAD_CURRENT_ACCOUNT(), // run once on app load to check user auth
  ])
}
