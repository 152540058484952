import { notification } from 'antd'
import CommonApi from './Apiservices/CommonAPI'


export default create

export async function create(trackdisablecontent, trackdisabletitle, trackdisapprovaltype) {
  const state = {
    res: false
  }
  const body = {
    content: trackdisablecontent,
    title: trackdisabletitle,
    disapproval_type: trackdisapprovaltype
  }
  await CommonApi.SaveData('/api/v1/admin/track/disapproval/reason/create', body)
    .then((response) => {
      notification.success({
        message: response.data.message,
      });
      state.res = true
      return state.res

    }).catch(error => {
      notification.warning({
        message: error.message,
      });
    })
  return state.res
}

export async function Edit(editcontent, edittitle, editdisapprovaltype, id) {
  const state = {
    res: false
  }

  const body = {
    content: editcontent,
    title: edittitle,
    disapproval_type: editdisapprovaltype
  }

  await CommonApi.SaveData(`/api/v1/admin/track/disapproval/reason/update/${id}`, body)
    .then((response) => {
      notification.success({
        message: response.data.message,
      });
      state.res = true
      return state

    }).catch(error => {
      notification.warning({
        message: error.message,
      });
    })
  return state
}
