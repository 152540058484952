const actions = {
  SET_STATE: 'user/SET_STATE',
  CREATE: 'hotpic/CREATE',
  EDIT: 'trackdetail/EDIT'
  // FORGOT: 'user/FORGOT',
  // UPDATEPASS: 'user/UPDATEPASS',
  // LOAD_CURRENT_ACCOUNT: 'user/LOAD_CURRENT_ACCOUNT',
  // LOGOUT: 'user/LOGOUT',
}

export default actions
