import { notification } from 'antd'
import CommonApi from './Apiservices/CommonAPI'


export default create

export function create(payload, editor) {
  const state = {
    res: false
  }
  const body = {
    code: payload.code,
    subject: payload.subject,
    content_html: payload.content_html,
    layout_id: payload.layout_id,
    is_custom: payload.is_custom,
    description: editor
  }
  CommonApi.SaveData('/api/v1/admin/mail/template/create', body)
    .then((response) => {
      notification.success({
        message: response.data.message,
      });
      state.res = true
      return state.res

    }).catch(error => {
      notification.warning({
        message: error.message,
      });
    })
  return state
}





export function Edit(payload, id, editor) {
  const state = {
    res: false
  }

  const body = {
    code: payload.code,
    subject: payload.subject,
    description: editor
  }

  CommonApi.SaveData(`/api/v1/admin/mail/template/update/${id}`, body)
    .then((response) => {
      notification.success({
        message: response.data.message,
      });
      state.res = true
      return state.res

    }).catch(error => {
      notification.warning({
        message: error.message,
      });
    })
  return state

}



