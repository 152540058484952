import { all, takeEvery, put, call } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import { create  , Edit } from 'services/instrument'
import actions from './actions'

export function* CREATE({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success=yield call(create,payload)
  if(success){
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    })
    return yield put(push('/instrument'))
  }

}
export function* EDIT({ payload,instrumentid}) {
  const { instrumentname } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success=yield call(Edit,instrumentname,instrumentid)
  if(success){
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    })
    return yield put(push('/instrument'))
  }

}

 
export default function* rootSaga() {
  yield all([
    takeEvery(actions.CREATE, CREATE),
    takeEvery(actions.EDIT, EDIT),
  ])
}
