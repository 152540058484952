import { all, takeEvery, put, call } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import { create , Edit } from 'services/mail'
import actions from './actions'

export function* CREATE({ payload,editor }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success=yield call(create,payload,editor)
  if(success){
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    })
    return yield put(push('/mail'))
  }
}
export function* EDIT({ payload,id,editor}) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success=yield call(Edit,payload,id,editor)
  if(success){
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    })
    return yield put(push('/mail'))
  }
}

 
export default function* rootSaga() {
  yield all([
    takeEvery(actions.CREATE, CREATE),
    takeEvery(actions.EDIT, EDIT),
  ])
}
