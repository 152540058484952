import { notification } from 'antd'
import CommonApi from './Apiservices/CommonAPI'


export default create

export async function create(payload, labelid, medalid) {

  const state = {
    res: false
  }
  const body = {
    label_id: labelid,

    label_medal_id: medalid
  }

  await CommonApi.SaveData('/api/v1/admin/label/medal/assign', body)
    .then((response) => {
      notification.success({
        message: response.data.message,
      });
      state.res = true
      return state.res

    }).catch(error => {

      notification.warning({
        message: error.message,
      });
    })
  return state.res
}

export async function Edit(labelname, paypalaccount, facebookinvited, bioapproved, autoapprovetracks, labeldescription, labelbiography,
  facebookurl, adminremarks, facebookinvitedat, labelpublisher, publisheripicae, proipicae, labelpro, agreementstiplykey, labelagreementdocument, id) {
    const state = {
    res: false
  }
  const body = {
    label_name: labelname,
    paypal_account: paypalaccount,
    facebook_invited: facebookinvited && facebookinvited !=='No' ? "Yes" : "No",
    bio_approved: bioapproved ?"Yes" : "No",
    description: labeldescription,
    biography: labelbiography,
    facebook: facebookurl,
    admin_remarks: adminremarks,
    facebook_invited_at: facebookinvitedat,
    publisher: labelpublisher,
    publisher_ipi_cae: publisheripicae,
    pro_ipi_cae: proipicae,
    pro: labelpro,
    agreement_stiply_key: agreementstiplykey,
    label_agreement_document: labelagreementdocument
  }

  await CommonApi.SaveData(`/api/v1/admin/label/update/${id}`, body)
    .then((response) => {

      notification.success({
        message: response.data.message,
      });
      state.res = true
      return state.res

    }).catch(error => {
      notification.warning({
        message: (error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message),
      });
    })
  return state.res
}