import { notification } from 'antd'
import CommonApi from './Apiservices/CommonAPI'

/*eslint-disable*/
// export default create
export function Edit(sellcopyright, contractpro, contractproipicae, contractpublisher, contractpublisheripicae) {
  const state = {
    res: false
  }
  const body =
  {
    sell_copyright: sellcopyright,
    pro: contractpro,
    pro_ipi_cae: contractproipicae,
    publisher: contractpublisher,
    publisher_ipi_cae: contractpublisheripicae
  }
  CommonApi.SaveData('/api/v1/producer/contract/sign', body)
    .then((response) => {
      notification.success({
        message: response.data.message,
      });
      state.res = true
      return state

    }).catch(error => {
      notification.warning({
        message: error.message,
      });
    })
  return state

}
