import { all, takeEvery, put, call } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import { create , Edit } from 'services/payment'
import actions from './actions'

/*eslint-disable*/
export function* CREATE({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success=yield call(create,payload)
  if(success){
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    })
    return yield put(push('/payment'))

  }
  
}
export function* EDIT({ payload,paymentid }) {
  const { 
    method,
    gateway ,
    countries,
    costflat ,
    costpercentage  } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  
  const success=yield call(Edit , method , gateway,countries, costflat,costpercentage,paymentid)
  if(success){
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    })
    return yield put(push('/payment'))
  }
  
}


export default function* rootSaga() {
  yield all([
    takeEvery(actions.CREATE, CREATE),
    takeEvery(actions.EDIT, EDIT),
    // takeEvery(actions.LOAD_CURRENT_ACCOUNT, LOAD_CURRENT_ACCOUNT),
    // takeEvery(actions.LOGOUT, LOGOUT),
    // takeEvery(actions.FORGOT, FORGOT),
    // takeEvery(actions.UPDATEPASS, UPDATEPASS),
    // LOAD_CURRENT_ACCOUNT(), // run once on app load to check user auth
  ])
}
