import { all, takeEvery, put, call } from 'redux-saga/effects'
import { create , Edit } from 'services/createdisable'
import actions from './actions'

export function* CREATE({ payload}) {
   const { 
     disablecontent ,
     disabletitle,
   } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success=yield call(create,disabletitle,disablecontent)
  if(success){
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    })
   return success

  }
  
}

export function* EDIT({ payload,id  }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success=yield call(Edit,payload,id)
  if(success){
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    })
   return success

  }
  
}




export default function* rootSaga() {
  yield all([
    takeEvery(actions.CREATE, CREATE),
    takeEvery(actions.EDIT, EDIT),
  ])
}
