import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'
import Loadable from 'react-loadable'

import Loader from 'components/LayoutComponents/Loader'
import IndexLayout from 'layouts'
import NotFoundPage from 'pages/404'

const loadable = loader =>
  Loadable({
    loader,
    delay: false,
    loading: () => <Loader />,
  })
const routes = [
  // System Pages
  {
    path: '/user/login',
    component: loadable(() => import('pages/user/login')),
    exact: true,
  },
  {
    path: '/user/forgot',
    component: loadable(() => import('pages/user/forgot')),
    exact: true,
  },
  {
    path: '/user/password/reset/:token',
    component: loadable(() => import('pages/user/forgot/getlinkbyemail')),
    exact: true,
  },
  {
    path: '/twofa',
    component: loadable(() => import('pages/2fa')),
    exact: true,
  },
  // Dashboards
  {
    path: '/dashboard',
    component: loadable(() => import('pages/dashboard/dash')),
  },
  {
    // path: '/dashboard/beta',
    path: '/tracks',
    component: loadable(() => import('pages/dashboard/tracks')),
    exact: true,
  },
  {
    // path: '/dashboard/crypto',
    path: '/pages',
    component: loadable(() => import('pages/dashboard/pages')),
    exact: true,
  },
  // {
  //   path: '/users',
  //   component: loadable(() => import('pages/dashboard/users')),
  //   exact: true,
  // },
  {
    path: '/labels',
    component: loadable(() => import('pages/dashboard/labels')),
    exact: true,
  },

  // Default Pages
  {
    path: '/pages/login-alpha',
    component: loadable(() => import('pages/pages/login-alpha')),
    exact: true,
  },
  {
    path: '/pages/login-beta',
    component: loadable(() => import('pages/pages/login-beta')),
    exact: true,
  },
  {
    path: '/pages/register',
    component: loadable(() => import('pages/pages/register')),
    exact: true,
  },
  {
    path: '/pages/lockscreen',
    component: loadable(() => import('pages/pages/lockscreen')),
    exact: true,
  },
  {
    path: '/pages/pricing-table',
    component: loadable(() => import('pages/pages/pricing-table')),
    exact: true,
  },
  {
    path: '/pages/invoice',
    component: loadable(() => import('pages/pages/invoice')),
    exact: true,
  },

  // Apps
  {
    path: '/apps/messaging',
    component: loadable(() => import('pages/apps/messaging')),
    exact: true,
  },
  {
    path: '/apps/mail',
    component: loadable(() => import('pages/apps/mail')),
    exact: true,
  },
  {
    path: '/apps/profile',
    component: loadable(() => import('pages/apps/profile')),
    exact: true,
  },
  {
    path: '/apps/gallery',
    component: loadable(() => import('pages/apps/gallery')),
    exact: true,
  },

  // Ecommerce
  {
    path: '/ecommerce/dashboard',
    component: loadable(() => import('pages/ecommerce/dashboard')),
    exact: true,
  },
  {
    path: '/ecommerce/products-catalog',
    component: loadable(() => import('pages/ecommerce/products-catalog')),
    exact: true,
  },
  {
    path: '/ecommerce/product-details',
    component: loadable(() => import('pages/ecommerce/product-details')),
    exact: true,
  },
  {
    path: '/ecommerce/product-edit',
    component: loadable(() => import('pages/ecommerce/product-edit')),
    exact: true,
  },
  {
    path: '/ecommerce/products-list',
    component: loadable(() => import('pages/ecommerce/products-list')),
    exact: true,
  },
  {
    path: '/ecommerce/orders',
    component: loadable(() => import('pages/ecommerce/orders')),
    exact: true,
  },
  {
    path: '/ecommerce/cart',
    component: loadable(() => import('pages/ecommerce/cart')),
    exact: true,
  },

  // Layout
  {
    path: '/layout/bootstrap',
    component: loadable(() => import('pages/layout/bootstrap')),
    exact: true,
  },
  {
    path: '/layout/card',
    component: loadable(() => import('pages/layout/card')),
    exact: true,
  },
  {
    path: '/layout/utilities',
    component: loadable(() => import('pages/layout/utilities')),
    exact: true,
  },
  {
    path: '/layout/typography',
    component: loadable(() => import('pages/layout/typography')),
    exact: true,
  },
  {
    path: '/layout/mail-templates',
    component: loadable(() => import('pages/layout/mail-templates')),
    exact: true,
  },

  // Icons
  {
    path: '/icons/fontawesome',
    component: loadable(() => import('pages/icons/fontawesome')),
    exact: true,
  },
  {
    path: '/icons/linear',
    component: loadable(() => import('pages/icons/linear')),
    exact: true,
  },
  {
    path: '/icons/icomoon',
    component: loadable(() => import('pages/icons/icomoon')),
    exact: true,
  },

  // Charts
  {
    path: '/charts/chartist',
    component: loadable(() => import('pages/charts/chartist')),
    exact: true,
  },
  {
    path: '/charts/chart',
    component: loadable(() => import('pages/charts/chart')),
    exact: true,
  },
  {
    path: '/charts/peity',
    component: loadable(() => import('pages/charts/peity')),
    exact: true,
  },
  {
    path: '/charts/c3',
    component: loadable(() => import('pages/charts/c3')),
    exact: true,
  },
  // orders
  {
    path: '/orders/order',
    component: loadable(() => import('pages/orders/order')),
    exact: true,
  },

  {
    path: '/audition',
    component: loadable(() => import('pages/audition')),
    exact: true,

    //  className: 'ant-menu-item {window.location.pathname===/audition/labeldetails/:id ? "ant-menu-item-selected" : " "}',
  },
  {
    path: '/audition/labeldetails/:id',
    component: loadable(() => import('pages/audition/labeldetails')),
    exact: true,
    title: 'Auditions',
    key: 'Auditions',


  },
  // setting
  {
    path: '/setting',
    component: loadable(() => import('pages/setting')),
    exact: true,
  },
  {
    path: '/currency',
    component: loadable(() => import('pages/currency')),
    exact: true,
  },

  // Genre
  {
    path: '/edit/genres/:id',
    component: loadable(() => import('pages/generes/edit')),
    exact: true,
  },
  {
    path: '/genres',
    component: loadable(() => import('pages/generes')),
    exact: true,
  },
  {
    path: '/genres/add-genres',
    key: 'Add',
    title: 'Add',
    name: 'add',
    component: loadable(() => import('pages/generes/add-genere')),
    exact: true,
  },
  // label variable
  {
    path: '/label-variable/labelmedals',
    component: loadable(() => import('pages/label-variable/Label_Medals')),
    exact: true,
  },
  {
    path: '/labelmedals/update/:id',
    component: loadable(() => import('pages/label-variable/Label_Medals/Edit_Medal')),
    exact: true,
  },
  {
    path: '/labelmedals/create-medal',
    component: loadable(() => import('pages/label-variable/Label_Medals/Add_Medal')),
    exact: true,
  },
  // {
  //   path: '/label-variable/label-medals',
  //   component: loadable(() => import('pages/label-variable/label-medals')),
  //   exact: true,
  // },
  {
    path: '/label-variable/label-percentages',
    component: loadable(() => import('pages/label-variable/label-percentages')),
    exact: true,
  },
  {
    path: '/label-variable/price-labels',
    component: loadable(() => import('pages/label-variable/price-labels')),
    exact: true,
  },
  {
    path: '/label-variable/update-price-labels/:id',
    component: loadable(() => import('pages/label-variable/price-labels/Edit_Price')),
    exact: true,
  },
  // label medal
  {
    path: '/label-medals/new-medal',
    component: loadable(() => import('pages/label-variable/label-medals/new-medal')),
    exact: true,
  },
  {
    path: '/price-labels/add-price',
    component: loadable(() => import('pages/label-variable/price-labels/add-price')),
    exact: true,
  },
  // percentage label
  {
    path: '/label-percentages/add-percentage',
    component: loadable(() => import('pages/label-variable/label-percentages/add-percentage')),
    exact: true,
  },
  {
    path: '/label-percentages/update-percentage/:id',
    component: loadable(() => import('pages/label-variable/label-percentages/edit-percentage')),
    exact: true,
  },
  // payment
  {
    path: '/payment',
    component: loadable(() => import('pages/payment')),
    exact: true,
  },
  {
    path: '/payment/add-payment',
    component: loadable(() => import('pages/payment/add-payment')),
    exact: true,
  },
  {
    path: '/payment/edit-payment/:id',
    component: loadable(() => import('pages/payment/edit-payment')),
    exact: true,
  },
  // mailing list type
  {
    path: '/mailingListTypes',
    component: loadable(() => import('pages/mailingListTypes')),
    exact: true,
  },
  {
    path: '/mailingListTypes/add-maillisttype',
    component: loadable(() => import('pages/mailingListTypes/add-maillisttype')),
    exact: true,
  },
  {
    path: '/mailingListTypes/edit-maillisttype/:id',
    component: loadable(() => import('pages/mailingListTypes/edit-maillisttype')),
    exact: true,
  },
  // faq
  {
    path: '/faq/faqs',
    component: loadable(() => import('pages/faq/faqs')),
    exact: true,
  },
  {
    path: '/faq/faqs/add-faq',
    component: loadable(() => import('pages/faq/faqs/add-faq')),
    exact: true,
  },
  {
    path: '/faq/faqs/edit-faq/:id',
    component: loadable(() => import('pages/faq/faqs/edit-faq')),
    exact: true,
  },
  // faq category
  {
    path: '/faq/faq-category',
    component: loadable(() => import('pages/faq/faq-category')),
    exact: true,
  },
  {
    path: '/faq/faq-category/add-faqcategory',
    component: loadable(() => import('pages/faq/faq-category/add-faqcategory')),
    exact: true,
  },
  {
    path: '/faq/faq-category/edit-faqcategory/:id',
    component: loadable(() => import('pages/faq/faq-category/edit-faqcategory')),
    exact: true,
  },
  // faq tag
  {
    path: '/faq/faq-tags',
    component: loadable(() => import('pages/faq/faq-tags')),
    exact: true,
  },
  {
    path: '/faq/faq-tags/add-faqtag',
    component: loadable(() => import('pages/faq/faq-tags/add-faqtag')),
    exact: true,
  },
  {
    path: '/faq/faq-tags/edit-faqtag/:id',
    component: loadable(() => import('pages/faq/faq-tags/edit-faqtag')),
    exact: true,
  },
  // pages
  {
    path: '/pages/add-page',
    component: loadable(() => import('pages/dashboard/pages/add-page')),
    exact: true,
  },
  {
    path: '/pages/edit-page/:id',
    component: loadable(() => import('pages/dashboard/pages/edit-page')),
    exact: true,
  },
  // users
  {
    path: '/users',
    component: loadable(() => import('pages/dashboard/users')),
    exact: true,
  },
  // instrument
  {
    path: '/instrument',
    component: loadable(() => import('pages/instrument')),
    exact: true,
  },
  {
    path: '/instrument/add-instrument',
    component: loadable(() => import('pages/instrument/add-instrument')),
    exact: true,
  },
  {
    path: '/instrument/edit-instrument/:id',
    component: loadable(() => import('pages/instrument/edit-instrument')),
    exact: true,
  },
  //  category
  {
    path: '/categorymanagement',
    component: loadable(() => import('pages/categorymanagement')),
    exact: true,
  },
  {
    path: '/categorymanagement/addcategory',
    component: loadable(() => import('pages/categorymanagement/addcategory')),
    exact: true,
  },
  {
    path: '/categorymanagement/editcategory/:id',
    component: loadable(() => import('pages/categorymanagement/editcategory')),
    exact: true,
  },
  {
    path: '/users/user-detail/:id',
    component: loadable(() => import('pages/dashboard/users/user-detail')),
    exact: true,
  },
  {
    path: '/order/order-detail/:id',
    component: loadable(() => import('pages/orders/order/order-detail')),
    exact: true,
  },

  {
    path: '/order-detail/edit-order/:id',
    component: loadable(() => import('pages/orders/order/order-detail/edit-order')),
    exact: true,
  },

  {
    path: '/tracks/tracks-detail/:id',
    component: loadable(() => import('pages/dashboard/tracks/tracks-detail')),
    exact: true,
  },
  {
    path: '/relevant-tracks/:slug',
    component: loadable(() => import('pages/dashboard/tracks/relevant-tracks')),
    exact: true,
  },
  {
    path: '/new-demo/:id?',
    component: loadable(() => import('pages/dashboard/tracks/new-demos')),
    exact: true,
  },
  {
    path: '/labels/labelinformation/:id',
    component: loadable(() => import('pages/dashboard/labels/labelinformation')),
    exact: true,
  },
  // track report reasone
  {
    path: '/all-track-reports',
    component: loadable(() => import('pages/track_report_reason')),
    exact: true,
  },
  {
    path: '/all-track-reports/add-track-report',
    component: loadable(() => import('pages/track_report_reason/add-track-report')),
    exact: true,
  },
  {
    path: '/all-track-reports/edit-report/:id',
    component: loadable(() => import('pages/track_report_reason/edit-track-report')),
    exact: true,
  },
  {
    path: '/trackreported-bycustomer',
    component: loadable(() => import('pages/track_report_reason/track-reported-by-customer')),
    exact: true,
  },
  {
    path: '/coupons',
    component: loadable(() => import('pages/coupons')),
    exact: true,
  },
  {
    path: '/coupons/create',
    component: loadable(() => import('pages/coupons/create-coupon')),
    exact: true,
  },
  {
    path: '/coupons/edit/:id',
    component: loadable(() => import('pages/coupons/update-coupon')),
    exact: true,
  },
  {
    path: '/banners',
    component: loadable(() => import('pages/banners')),
    exact: true,
  },
  {
    path: '/banners/create',
    component: loadable(() => import('pages/banners/create-banner')),
    exact: true,
  },
  {
    path: '/banners/edit/:id',
    component: loadable(() => import('pages/banners/update-banner')),
    exact: true,
  },
  {
    path: '/hotcoin-products',
    component: loadable(() => import('pages/hotcoin-products')),
    exact: true,
  },
  {
    path: '/hotcoin-products/create',
    component: loadable(() => import('pages/hotcoin-products/create-product')),
    exact: true,
  },
  {
    path: '/hotcoin-products/edit/:id',
    component: loadable(() => import('pages/hotcoin-products/update-product')),
    exact: true,
  },
  {
    path: '/hotcoin-rewards',
    component: loadable(() => import('pages/hotcoin-rewards')),
    exact: true,
  },
  {
    path: '/hotcoin-rewards/create',
    component: loadable(() => import('pages/hotcoin-rewards/create-reward')),
    exact: true,
  },
  {
    path: '/hotcoin-rewards/edit/:id',
    component: loadable(() => import('pages/hotcoin-rewards/update-reward')),
    exact: true,
  },
]


class Router extends React.Component {

  render() {

    const { history } = this.props;
    return (
      <ConnectedRouter history={history}>
        <IndexLayout>
          <Switch>
            <Route exact path="/" render={() => <Redirect to="/dashboard" />} />
            {routes.map(route => (
              <Route
                path={route.path}
                component={route.component}
                key={route.path}
                exact={route.exact}
              />
            ))}
            <Route component={NotFoundPage} />
          </Switch>
        </IndexLayout>
      </ConnectedRouter>
    )
  }
}

export default Router
