import { notification } from 'antd'
import CommonApi from './Apiservices/CommonAPI'


export default create


export function create(payload, file) {
  const state = {
    res: false
  }
  const formData = new FormData();
  formData.append('track_category', payload.track_category)
  formData.append('track_category_image', file)

  CommonApi.SaveData('/api/v1/admin/category/create', formData)
    .then((response) => {
      notification.success({
        message: response.data.message,
      });
      state.res = true
      return state

    }).catch(error => {
      notification.warning({
        message: error,
      });
    })
  return state

}


export async function Edit(trackcategory, id, file) {
  const state = {
    res: false
  }


  const formData = new FormData();
  formData.append('track_category', trackcategory)
  formData.append('track_category_image', file)

  await CommonApi.SaveData(`/api/v1/admin/category/update/${id}`, formData)
    .then((response) => {
      notification.success({
        message: response.data.message,
      });
      state.res = true
      return state.res

    }).catch(error => {
      notification.warning({
        message: error.message,
      });
    })
  return state.res
}
