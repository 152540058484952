import React from 'react'
//  import HomeMenu from './HomeMenu'
// import LiveSearch from './LiveSearch'
import ProfileMenu from './ProfileMenu'
import styles from './style.module.scss'
import CommonAPI from '../../../services/Apiservices/CommonAPI'

class TopBar extends React.Component {
  render() {
    return (
      <div className={styles.topbar}>
        <div className="mr-auto ml-2">
          {/* <LiveSearch /> */}
          {CommonAPI.url}
        </div>
        {/* <div className="mr-4">
          <HomeMenu />
        </div> */}
        <ProfileMenu />
      </div>
    )
  }
}

export default TopBar
