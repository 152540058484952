import { notification } from 'antd'
import CommonApi from './Apiservices/CommonAPI'
/* eslint-disable */

export async function login(email, password, code) {
  const state = {
    res: false,
    data: ''

  }

  const body = {
    email: email,
    password: password,
    code: code,
  }

  await CommonApi.Login('/api/login', body)
    .then((response) => {
      if (response.data.data.type == "Admin") {
        localStorage.setItem("token", true)
        localStorage.setItem("type", response.data.data.type)
        localStorage.setItem("name", response.data.data.name)
        localStorage.setItem("email", response.data.data.email)
        localStorage.setItem("Token", response.data.token)
        state.res = true
        state.data = response
        return [{ data: state.res, response: state.data }]
      } else {
        notification.warn({
          message: 'You Are Not Admin'
        })
      }
    })
    .catch(error => {
      return notification.warning({
        message: error.request.statusText,
        description: error.response.data.error,
      })
    })
  return [{ data: state.res, response: state.data }]
}

export async function currentAccount() {
  return await CommonApi.GetDataInfo('/api/profile')
    .then((response) => {
      if (response.data.data.type == "Admin") {
        return [{ data: true, response: response }]
      } else {
        notification.warn({
          message: 'You Are Not Admin'
        })
      }
    })
    .catch(error => {
      return notification.warning({
        message: error.request.statusText,
        description: error.response.data.error,
      })
    })
}

export async function logout() {
  localStorage.clear()
  CommonApi.Login('/api/logout', body)
    .catch(error => {
    return notification.warning({
      message: error.request.statusText,
      description: error.response.data.error,
    })
    .finally(response => {
      state.res = false
      return [{ data: state.res, response: response }]
    })
  })
}
export async function Forgot(email) {
  const state = {
    res: false
  }
  const body = {
    email: email,
  }

  const requestOptions = {
    method: 'POSt',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ email })
  };
  await CommonApi.Forgot(`/api/password/create`, body)
    .then((response) => {
      if (!response.ok) {
        notification.success({
          message: response.data.message
        })
      }
      state.res = response.ok
      return state.res
    }).catch(error => {
      let vat = JSON.parse(error.request.response);
      notification.warn({
        message: vat.message
      })
    })
  return state.res
}

export async function Updatepass(email, password, cpassword, token) {
  const state = {
    res: false
  }
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'multipart/form-data' },

  };
  const body = {
    email: email, password: password, password_confirmation: cpassword, token: token
  }

  CommonApi.SaveDataWithoutLogin("/api/password/reset", body)
    .then((response) => {
      notification.success({
        message: response.data.message,
      })
        state.res = response.ok
        return state.res
    }).catch(()=>{
      notification.warning({
        message: "error",
        description: "error",
      })
    })
  return state.res
}